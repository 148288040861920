import React from "react";

// @material-tailwind/react
import {
  Button,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Card,
  CardBody,
} from "@material-tailwind/react";
import "../../../App.css";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import Reports from "../reports";
import { useSelector } from "react-redux";

function KpiCard({ title, price }) {
  const { product } = useSelector((state) => state.product);
  const USD1 = product.filter((e) => e.currency === "USD" && e.type == 0);
  const USD2 = product.filter((e) => e.currency === "USD" && e.type == 1);
  const totalUSD1 = USD1.reduce((sum, e) => sum + e.price, 0);
  const totalUSD2 = USD2.reduce((sum, e) => sum + e.price, 0);
  const totalUSD = totalUSD1 - totalUSD2;

  const IQD1 = product.filter((e) => e.currency === "IQD" && e.type == 0);
  const IQD2 = product.filter((e) => e.currency === "IQD" && e.type == 1);
  const totalIQD1 = IQD1.reduce((sum, e) => sum + e.price, 0);
  const totalIQD2 = IQD2.reduce((sum, e) => sum + e.price, 0);
  const totalIQD = totalIQD1 - totalIQD2;

  return (
    <Card className="shadow-lg shadow-[#071975] rounded-2xl black-foto border-none ">
      <CardBody className="py-4 px-8">
        <div className="flex justify-between items-center ">
          <Typography as="div" color="white" className="mt-1   ">
            <h3 className="font-bold font-tajawal">
              {price === 2
                ? totalUSD.toLocaleString("en-US")
                : totalIQD.toLocaleString("en-US")}
              <span className="ml-2 text-[10px] ">
                {price === 2 ? "USD" : "IQD"}
              </span>
            </h3>
          </Typography>
          <Typography className="font-bold  text-white font-tajawal">
            {title}
          </Typography>
          {/* <div className="flex items-center gap-1">
            {icon}
            <Typography color={color} className="font-medium !text-xs">
              {percentage}
            </Typography>
          </div> */}
        </div>
      </CardBody>
    </Card>
  );
}

const data = [
  {
    title: "مجموع الدينار",
    price: 1,
  },
  {
    title: "مجموع الدولار",
    price: 2,
  },
];

function Home() {
  return (
    <section className="container mx-auto  px-4 md:px-6">
      {/* <div className="mt-6 mb-4 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 items-center md:gap-2.5 gap-4 ">
        {data.map((props, key) => (
          <KpiCard key={key} {...props} />
        ))}
      </div>
      <Reports /> */}
    </section>
  );
}

export default Home;
