import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { PowerIcon } from "@heroicons/react/24/solid";
import {
  UserPlusIcon,
  PresentationChartBarIcon,
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { getNews } from "../../context/actions/getaction";

function NavList({ onLogin }) {
  const dispatch = useDispatch();

  const handleLogout = () => {
    // إزالة الرمز المميز ووقت انتهاء الجلسة من LocalStorage
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");

    // تحديث حالة الجلسة
    onLogin(false);
  };

  return (
    <ul
      dir="rtl"
      className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6"
    >
      <Typography
        as="li"
        variant="small"
        color="white"
        className="p-1 font-medium"
      >
        <Link
          onClick={() => {
            dispatch(getNews());
          }}
          to={"/"}
          className="flex items-center hover:text-[#171f33] transition-colors font-tajawal"
        >
          <PresentationChartBarIcon className="h-5 w-5 ml-2" /> الرئيسية
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="white"
        className="p-1 font-medium"
      >
        <Link
          to={"/form"}
          className="flex items-center hover:text-[#171f33] transition-colors font-tajawal"
        >
          <UserPlusIcon className="h-5 w-5 ml-2" />
          إضافة عميل
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="white"
        className="p-1 font-medium"
      >
        <Link
          to={"/addForm"}
          className="flex items-center hover:text-[#171f33] transition-colors font-tajawal"
        >
          <UserPlusIcon className="h-5 w-5 ml-2" />
          تسليم حواله
        </Link>
      </Typography>

      <Typography
        as="li"
        variant="small"
        color="white"
        className="p-1 font-medium"
      >
        <Link
          onClick={handleLogout} // استدعاء handleLogout عند تسجيل الخروج
          className="flex items-center hover:text-[#171f33] transition-colors font-tajawal"
        >
          <PowerIcon className="h-5 w-5 ml-2" />
          تسجيل الخروج
        </Link>
      </Typography>
    </ul>
  );
}

export default function NavBar({ onLogin }) {
  const [openNav, setOpenNav] = React.useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Navbar
      color="blue"
      className="mx-auto max-w-screen-xl px-6 py-3  border-none shadow-lg "
    >
      <div className="flex items-center justify-between text-blue-gray-900">
        <Typography
          color="white"
          variant="h6"
          className="mr-4 cursor-pointer py-1.5 font-tajawal"
        >
          الايادي فلاي
        </Typography>
        <div className="hidden lg:block">
          <NavList onLogin={onLogin} />
        </div>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6 text-white" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6 text-white" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList onLogin={onLogin} openNav={openNav} setOpenNav={setOpenNav} />
      </Collapse>
    </Navbar>
  );
}
