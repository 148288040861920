import {
  Card,
  Input,
  Button,
  Typography,
  Alert,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { addUser } from "../../../context/actions/postaction";

export default function Form() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [photo, setPhoto] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (success !== null) {
      const timer = setTimeout(() => {
        setSuccess(null);
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  const handlePhotoChange = (event) => {
    setPhoto(event.target.files[0]);
  };

  const uploadFile = async (file) => {
    const APP_ID = "4fkNRFIeX3AljnF3brkpMfwJw2S9sBc1o6LzUqT7";
    const API_KEY = "k0DfnGHgZTg6QOHfE5AV7vrMz1qyByRJ7Xwx1aYq";
    const MASTER_KEY = "PKBxUyh2cNMNFYpkxI5fgVy2vJ641ZHvjZcqzPHh";

    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);

    try {
      const response = await axios.post(
        "https://parseapi.back4app.com/files/" + file.name,
        formData,
        {
          headers: {
            "X-Parse-Application-Id": APP_ID,
            "X-Parse-REST-API-Key": API_KEY,
            "X-Parse-Master-Key": MASTER_KEY,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error("فشل تحميل الصورة");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!name || !phone || !photo) {
      setError("جميع الحقول مطلوبة.");
      setSuccess(false);
      return;
    }

    try {
      const photoFile = await uploadFile(photo);

      const formData = {
        name: name,
        phone: phone,
        photo: {
          __type: "File",
          name: photoFile.name,
          url: photoFile.url,
        },
      };

      const response = await dispatch(addUser(formData));

      if (response.status === 201) {
        setSuccess(true);
        setName("");
        setPhone("");
        setPhoto(null);
      } else {
        setSuccess(false);
        setError("حدث خطأ أثناء إرسال النموذج.");
      }
    } catch (error) {
      setSuccess(false);
      setError(error.message || "حدث خطأ أثناء إرسال النموذج.");
    }
  };

  return (
    <Card className="items-center" color="transparent" shadow={false}>
      <form
        className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96"
        onSubmit={handleSubmit}
      >
        {success === true ? (
          <div dir="rtl" className="flex w-full flex-col gap-2">
            <Alert className="font-tajawal text-center" color="green">
              تم حفظ البيانات بنجاح.
            </Alert>
          </div>
        ) : success === false && error ? (
          <div dir="rtl" className="flex w-full flex-col gap-2">
            <Alert className="font-tajawal items-center" color="red">
              {error}
            </Alert>
          </div>
        ) : null}
        <div className="mb-1 flex flex-col gap-6">
          <Typography
            variant="h6"
            color="white"
            className="-mb-3 font-tajawal text-right"
          >
            الأسم
          </Typography>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            color="white"
            size="lg"
            placeholder="الأسم"
            className="!border-t-blue-gray-200 font-tajawal text-right"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
          />
          <Typography
            variant="h6"
            color="white"
            className="-mb-3 font-tajawal text-right"
          >
            رقم الهاتف
          </Typography>
          <Input
            type="number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            color="white"
            size="lg"
            placeholder="رقم الهاتف"
            className="!border-t-blue-gray-200 font-tajawal text-right"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
          />
          <Typography
            variant="h6"
            color="white"
            className="-mb-3 font-tajawal text-right"
          >
            الصورة
          </Typography>
          <Input
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
            color="white"
            size="lg"
            className="!border-t-blue-gray-200 font-tajawal text-right"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
          />
        </div>
        <Button
          type="submit"
          className="mt-6 font-tajawal text-lg bg-[#6495ed]"
          fullWidth
        >
          حفظ
        </Button>
      </form>
    </Card>
  );
}
