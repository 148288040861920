import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./context/store";
import { ThemeProvider } from "@material-tailwind/react";
import Parse from "parse";

Parse.initialize(
  "4fkNRFIeX3AljnF3brkpMfwJw2S9sBc1o6LzUqT7",
  "z2k2d8niNqnMppaL8obN1fu4oJc0fxDisKNb26aq"
);
Parse.serverURL = "https://parseapi.back4app.com/";

Parse.masterKey = "PKBxUyh2cNMNFYpkxI5fgVy2vJ641ZHvjZcqzPHh";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
