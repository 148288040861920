import {
  Card,
  Input,
  Button,
  Typography,
  Select,
  Option,
} from "@material-tailwind/react";
import { useState, useRef } from "react";

export default function AddForm() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("USD");
  const printRef = useRef();

  const handlePrint = () => {
    const printArea = printRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printArea;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // لإعادة تحميل الصفحة بعد الطباعة
  };

  const getCurrentDate = () => {
    const today = new Date();
    return today.toLocaleDateString("ar-EG", {
      numberingSystem: "latn", // لضمان عرض الأرقام بالإنجليزية
    });
  };

  return (
    <div className="p-4">
      <Card className="items-center" color="transparent" shadow={false}>
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-1 flex flex-col gap-6">
            {/* الحقول المعتادة مثل الاسم، الهاتف، المبلغ، العملة، والملاحظة */}
            <Typography
              variant="h6"
              color="white"
              className="-mb-3 font-tajawal text-right"
            >
              الاسم
            </Typography>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              color="white"
              size="lg"
              placeholder="الاسم"
              className="!border-t-blue-gray-200 font-tajawal text-right"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />

            <Typography
              variant="h6"
              color="white"
              className="-mb-3 font-tajawal text-right"
            >
              رقم الهاتف
            </Typography>
            <Input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              color="white"
              size="lg"
              placeholder="رقم الهاتف"
              className="!border-t-blue-gray-200 font-tajawal text-right"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />

            <Typography
              variant="h6"
              color="white"
              className="-mb-3 font-tajawal text-right"
            >
              المبلغ
            </Typography>
            <Input
              type="tel"
              inputMode="numeric"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              color="white"
              size="lg"
              placeholder="المبلغ"
              className="!border-t-blue-gray-200 font-tajawal text-right"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />

            <Typography
              variant="h6"
              color="white"
              className="-mb-3 font-tajawal text-right"
            >
              العملة
            </Typography>
            <Select
              value={currency}
              onChange={(e) => setCurrency(e)}
              color="blue-gray"
              size="lg"
              className="font-tajawal text-right text-white"
              label="العملة"
              labelProps={{
                className: "font-tajawal",
              }}
            >
              <Option value="USD" className="font-tajawal">
                دولار أمريكي
              </Option>
              <Option value="IQD" className="font-tajawal">
                دينار عراقي
              </Option>
              <Option value="TRY" className="font-tajawal">
                ليرة تركية
              </Option>
              <Option value="EUR" className="font-tajawal">
                يورو
              </Option>
            </Select>

            <Typography
              variant="h6"
              color="white"
              className="-mb-3 font-tajawal text-right"
            >
              الملاحظة
            </Typography>
            <Input
              value={note}
              onChange={(e) => setNote(e.target.value)}
              color="white"
              size="lg"
              placeholder="الملاحظة"
              className="!border-t-blue-gray-200 font-tajawal text-right"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
          </div>
          <Button
            onClick={handlePrint}
            className="mt-6 font-tajawal text-lg bg-[#6495ed]"
            fullWidth
          >
            طباعة
          </Button>
        </form>
      </Card>

      {/* المنطقة التي سيتم طباعتها */}
      <div
        className="font-tajawal"
        ref={printRef}
        style={{
          width: "8cm",
          padding: "1cm",
          display: "none",
          fontSize: "18px", // تكبير حجم الخط
          textAlign: "center", // توسيط النص
          direction: "rtl", // لضبط النصوص من اليمين لليسار
        }}
      >
        <pre
          className="font-tajawal"
          style={{
            textAlign: "center", // توسيط النص
            direction: "rtl",
          }}
        >
          ****************************{"\n"}
          الأيادي فلاي{"\n"}
          ****************************{"\n"}
          الاسم: {name}
          {"\n"}
          رقم الهاتف: {phone}
          {"\n"}
          المبلغ: {price} {currency}
          {"\n"}
          الملاحظة: {note}
          {"\n"}
          ----------------------------{"\n"}
          التاريخ: {getCurrentDate()}
          {"\n"}
          {"\n"}
          التوقيع: __________________
          {"\n"}
          ****************************{"\n"}
        </pre>
      </div>
    </div>
  );
}
